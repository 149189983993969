import {Injectable, signal, WritableSignal} from '@angular/core'
import {HttpClient, HttpErrorResponse} from '@angular/common/http'
import {catchError, NEVER, Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {MatDialog} from '@angular/material/dialog'
import {ErrorDialogComponent} from '../common/error-dialog/error-dialog.component'
import {BankIDVerificationItem} from '../application/types'

@Injectable({
  providedIn: 'root'
})
export class VerifyService {
    /**
     * Holds the userData that we show the user after scanning is done
     */
    public userData: WritableSignal<BankIDVerificationItem> = signal({})

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  public send(code: string): Observable<any> {
    const url = `${environment.apiUrl}/verify`
    return this.http.put(url, {qrCode: code}).pipe(
      catchError((error: HttpErrorResponse) => {
        this.dialog.open(ErrorDialogComponent, {
          data: { status: error.status},
          disableClose: true
        })
        return NEVER
      })
    )
  }
}
