<div (swipeleft)="handleSwipeLeft.emit()" (swiperight)="handleSwipeRight.emit()">
    <div class="counter-holder" *ngIf="remainingScans >= 0">
        <div class="counter">{{remainingScans}}</div>
        <div>Antal scanningar kvar denna månad</div>
        <div *ngIf="remainingScans === 0">Du har tyvärr använt upp dina scanningar för denna månad</div>
    </div>
    <div class="counter-holder" *ngIf="remainingScans < 0">
        <div class="counter">5</div>
        <div>Antal scanningar kvar denna månad</div>
        <div *ngIf="remainingScans === 0">Du har tyvärr använt upp dina scanningar för denna månad</div>
    </div>
    <div class="video-holder">
        <video #video class="video-container"></video>
    </div>
    <div class="selector-holder">
        <p>Har du problem att scanna qr-koden så kan du prova att byta kamera här.</p>
        <mat-form-field>
            <mat-label>Byt kamera</mat-label>
            <mat-select [formControl]="selectedCamera" ngDefaultControl>
                <mat-option *ngFor="let camera of cameraList" [value]="camera.id">{{camera.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
