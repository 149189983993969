<div matDialogTitle>{{data.title}}</div>
<div matDialogContent>
    <mat-form-field style="padding-top: 6px">
        <mat-label>Tidpunkt:</mat-label>
        <input class="line-height" readonly matInput
               value="{{data.timeStamp | date: 'YYYY-MM-dd, HH:mm': undefined : 'fr'}}">
    </mat-form-field>

    <!--  IF Correct -->
    <mat-form-field style="padding-top: 6px" *ngIf="data.name">
        <mat-label>Personnummer:</mat-label>
        <input class="line-height" readonly matInput
               value="{{ data.personalNumber | slice:0:8 }}-{{ data.personalNumber | slice:8 }}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px" *ngIf="data.name">
        <mat-label>Namn:</mat-label>
        <input class="line-height" readonly matInput value="{{data.name}}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px" *ngIf="data.name">
        <mat-label>Ålder:</mat-label>
        <input class="line-height" readonly matInput value="{{data.age}}">
    </mat-form-field>

    <!--  IF Error-->
    <mat-form-field style="padding-top: 6px" *ngIf="data.errorCode">
        <mat-label>Felmeddelande:</mat-label>
        <input class="line-height" readonly matInput value="{{data.errorCode}}">
    </mat-form-field>

    <mat-form-field style="padding-top: 6px" *ngIf="data.details">
        <mat-label>Detaljer:</mat-label>
        <textarea rows="2" class="line-height" readonly matInput value="{{data.details}}"></textarea>
    </mat-form-field>

    <button color="primary" mat-dialog-close="true" mat-raised-button>Stäng</button>

</div>

