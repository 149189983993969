import {Component, OnInit} from '@angular/core'
import {VerifyService} from "../../services/verify.service"
import '@angular/common/locales/global/fr'

interface Translations {
  [key: string]: string
}

@Component({
  selector: 'aku-scan-result-dialog',
  templateUrl: './scan-result-dialog.component.html',
  styleUrls: ['./scan-result-dialog.component.scss']
})
export class ScanResultDialogComponent implements OnInit {
  public data: any

  constructor(
      private verifyService: VerifyService
  ) {
  }

  public ngOnInit() {
    this.data = this.verifyService.userData()
    this.translate()
  }

  private translate() {
    const translations: Translations = {
      'Invalid QR code': 'Felaktig QR kod',
      'QR code missing or empty': 'QR kod saknas eller är tom',
      'invalidParameters': 'Felaktiga parametrar'
    }

    if (translations.hasOwnProperty(this.data.details)) {
      this.data.details = translations[this.data.details]
    }

    if (translations.hasOwnProperty(this.data.errorCode)) {
      this.data.errorCode = translations[this.data.errorCode]
    }
  }
}
