<div class="holder">
    <div class="header-holder">
        <div [routerLink]="['/']" class="logo-holder">
            <img src="assets/id-logo.png" alt="id logo">
        </div>
        <div class="menu-holder">
            <button [matMenuTriggerFor]="menu" mat-button>
                <mat-icon class="icon">menu</mat-icon>
            </button>
            <mat-menu #menu>
                <button [routerLink]="['/', 'scan']" mat-menu-item>Scanna</button>
                <button [routerLink]="['/', 'help']" mat-menu-item>Hjälp</button>
                <button *ngIf="false" [routerLink]="['/', 'sign-up']" mat-menu-item>Registrera</button>
                <button mat-menu-item>Installera</button>
                <button (click)="logout()" mat-menu-item>Logga ut</button>
            </mat-menu>
        </div>
    </div>
</div>