import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import {catchError, EMPTY, filter} from 'rxjs'
import {IQrScannerFactory, QR_SCANNER_FACTORY} from '../../application/qr-scanner.provider'
import {VerifyService} from '../../services/verify.service'
import {LOCAL_STORAGE} from '../../application/local-storage.provider'
import QrScanner from 'qr-scanner'
import {FormControl} from '@angular/forms'
import {MatDialog} from '@angular/material/dialog'
import {ScanResultDialogComponent} from '../../common/scan-result-dialog/scan-result-dialog.component'
import {BankIDVerificationError, Client} from '../../application/types'
import Camera = QrScanner.Camera
import ScanResult = QrScanner.ScanResult

@Component({
  selector: 'aku-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements AfterViewInit {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  @Output() handleSwipeRight = new EventEmitter<Event>()

  @ViewChild('video') video: ElementRef = new ElementRef(undefined)

  public cameraList: Camera[] = []
  public selectedCamera: FormControl = new FormControl<string>('')

  public maxScansPerMonth = 5
  public remainingScans = -1

  private qrScanner: QrScanner | undefined


  constructor(
    @Inject(QR_SCANNER_FACTORY) private qrFactory: IQrScannerFactory,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
    public verifyService: VerifyService,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
  }

  public ngAfterViewInit() {
    this.selectedCamera.valueChanges.subscribe({
      next: (cameraId: string) => this.changeCamera(cameraId)

    })
    const savedCamera: string | null = this.injectedLocalStorage.getItem('camera')
    this.selectedCamera.setValue(savedCamera)

    this.qrScanner = this.qrFactory.getScanner(
      this.video.nativeElement,
      (result: ScanResult) => {
        if (this.checkScan(result.data)) {
          this.stop()
          this.identify(result.data)
        }
      },
      {returnDetailedScanResult: true, preferredCamera: savedCamera, maxScansPerSecond: 10}
    )

    setTimeout(() => {
      this.renderer.setStyle(this.video.nativeElement, 'opacity', 1)
      this.renderer.setStyle(this.video.nativeElement, 'height', '100%')
      this.renderer.setStyle(this.video.nativeElement, 'width', '100%')
      this.renderer.setStyle(this.video.nativeElement, 'visibility', 'visible')
      this.renderer.setStyle(this.video.nativeElement, 'clip-path', 'inset(30px 0)')
    }, 1)
    this.start()
  }

  public start(): void {
    if (this.qrScanner) {
      this.qrScanner.start().then(() => {
        this.qrFactory.listCameras(true).then((cameras: Camera[]) => this.cameraList = cameras)
      })
    }
  }

  public stop(): void {
    if (this.qrScanner) {
      this.qrScanner.stop()
    }
  }

  public changeCamera(cameraId: string): void {
    if (this.qrScanner) {
      this.qrScanner.setCamera(cameraId).then()
      this.injectedLocalStorage.setItem('camera', cameraId)
    }
  }

  private checkScan(scanData: string): boolean {
    const splitData: string[] = scanData.split('.')
    const conditionList: boolean[] = []
    conditionList.push(splitData[0] === 'BANKIDF')
    if (splitData.length === 4) {
      conditionList.push(splitData[1].length === 32)
      conditionList.push(typeof +splitData[2] === 'number')
      conditionList.push(splitData[3].length === 64)
    }
    return conditionList.reduce((acc: boolean, curr: boolean) => acc === curr, true)
  }

  private identify(scanResult: string): void {
    this.verifyService.send(scanResult).pipe(
      catchError(() => {
        this.openDialog()
        return EMPTY
      }),
      filter(Boolean)
    ).subscribe({
      next: (res: Client | BankIDVerificationError): void => {
        if ('personalNumber' in res) {
          this.remainingScans = this.maxScansPerMonth - res.quota
          this.verifyService.userData.set({...res, title: 'Legitimerad'})
        } else {
          this.verifyService.userData.set({...res, title: 'Ej legitimerad'})
        }
        this.openDialog()
      }
    })
  }

  private openDialog(): void {
    this.ngZone.run(() => {
      this.matDialog.open(ScanResultDialogComponent, {
        width: '300px',
        panelClass: 'dialog-style',
        disableClose: true
      }).afterClosed().pipe(
        filter(Boolean)
      ).subscribe({
        next: () => {
          this.start()
        }
      })
    })
  }
}