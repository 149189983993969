import {Component, OnInit} from '@angular/core'
import {ConfigService} from "../../services/config.service"
import {Router} from "@angular/router"
import {Subscription, timer} from "rxjs"
import {MatSnackBar} from "@angular/material/snack-bar"

@Component({
  selector: 'aku-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  constructor(public configService: ConfigService, private router: Router, private snackBar: MatSnackBar) {
  }

  private timer$: Subscription = new Subscription()

  public ngOnInit():void {
    const expireTime = this.configService.checkLogoutTime()
    const sessionTime = 60 * 3 * 1000 // 3 minutes

    this.timer$ = timer(0, sessionTime).subscribe({
      next: () => {
        if (expireTime === 0) {
          this.logout()
          this.stopTimer()
        } else if (Date.now() > expireTime - sessionTime) {
          this.snackBar.open(
              'Din session går ut om mindre är 3 minuter. Efter det måste du logga in igen.',
              'Okej!',
              {panelClass: 'snackbar-style'})
        }
      }
    })
  }

  public logout(): void {
    this.configService.logout()
    this.router.navigate(['/']).then()
  }

  private stopTimer(): void {
    this.timer$.unsubscribe()
  }
}
