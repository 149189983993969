import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {WelcomeComponent} from './1-welcome/welcome.component'
import {authGuard} from './application/auth.guard'
import {HELP_ROUTE_PATH, SCAN_ROUTE_PATH} from './application/data-types'
import {ScannerComponent} from "./3-logged-in/1-scanner/scanner.component"
import {HelpComponent} from "./2-help/help.component"
import {CompanySignUpComponent} from './4-company-sign-up/company-sign-up.component'
import {RegisterDoneComponent} from './4-company-sign-up/register-done/register-done.component'

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
    pathMatch: 'full'
  },
  {
    path: HELP_ROUTE_PATH,
    component: HelpComponent
  },
  {
    path: 'sign-up',
    component: CompanySignUpComponent,
  },
  {
    path: 'registered',
    component: RegisterDoneComponent,
  },
  {
    path: SCAN_ROUTE_PATH,
    component: ScannerComponent,
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
