<div class="holder"
     (swipeleft)="handleSwipeLeft.emit()"
     (swiperight)="handleSwipeRight.emit()"
     style="min-height: 50vh">
    <div class="text-holder">
        <h2>Välkommen Akirus legitimeringsverktyg!</h2>
        <p>Här kan du enkelt skanna ID-kort från BankID och få en överblick över dina tidigare utförda
            legitimeringar.</p>
        <p>För att komma igång behöver du bara klicka på en av knapparna nedan och logga in med ditt BankID</p>
        <div class="bankid-holder">
            <aku-bankid [bankIdUrl]="bankIdUrl" (accessToken)="configService.setToken($event)"></aku-bankid>
        </div>
    </div>
</div>
