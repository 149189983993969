<div class="holder">
  <div class="form-container">
    <h2>Registrera företag</h2>
    <form [formGroup]="form">
      <div class="form-group">
        <label for="vatNumber">Organisationsnummer</label>
        <input type="text" formControlName="vatNumber" id="vatNumber" placeholder="Ange ert organisationsnummer" />
        <div *ngIf="vatNumber.touched && vatNumber.errors" class="error-message">
          Kontrollera organisationsnumret
        </div>
      </div>

      <div class="form-group">
        <label for="name">Företagsnamn</label>
        <input type="text" formControlName="name" id="name" placeholder="Ange företagsnamn" />
      </div>

      <div class="form-group">
        <label for="street">Gata</label>
        <input type="text" formControlName="street" id="street" placeholder="Ange företagets gatuadress" />
      </div>
    </form>
  </div>

  <div *ngIf="form.valid" class="valid-form">
   <p class="sign-text">Vänligen signera med BankID</p>
    <aku-bankid
      [bankIdUrl]="bankIdUrl"
      (accessToken)="afterSigned({ status: 'ok', accessToken: $event })"
      [userRef]="sanitizeForm()"
    ></aku-bankid>
  </div>
</div>
