<div class="holder">
    <div class="text-holder">
        <h2>Hjälp & Användarguide</h2>
        <h3>Skanna</h3>
        <p>På sidan "Skanna" kan du skanna kunders ID-kort via BankID. Tryck <a [routerLink]="['/', SCAN_ROUTE_PATH]">här</a> eller välja "Scanna" i menyn för att
            komma till scanner-sidan (du måste vara inloggad för att kunna scanna). När du scannat en ID-kort kommer appen att berätta om det skannade ID-kortet
            är giltigt eller inte.<br>
            Har du problem att scanna kan prova att byta kamera när du är på scannings-sidan</p>
        <h3>Installera</h3>
        <p>Om du vill få snabb tillgång till appen kan du installera den på din telefon eller dators skrivbord.
            För att installera appen klickar du på menyn uppe till höger, väljer installera och följer instruktionerna.</p>
    </div>
</div>
