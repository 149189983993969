import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService} from "../services/config.service"
import {environment} from "../../environments/environment"
import {filter} from "rxjs"
import {SCAN_ROUTE_PATH} from "../application/data-types"

@Component({
  selector: 'aku-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  @Output() handleSwipeRight = new EventEmitter<Event>()

  bankIdUrl = environment.bankIdUrl

  constructor(public configService: ConfigService, public router: Router) {
  }

  public ngOnInit(): void {
this.configService.checkLoggedIn()
    this.configService.isLoggedIn.pipe(
        filter(Boolean)
    ).subscribe({
      next: () => this.router.navigate(['/', SCAN_ROUTE_PATH])
    })
  }
}
