import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BankIdModule} from '@akiru/bankid'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatButtonModule} from '@angular/material/button'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AuthInterceptor} from './application/auth-interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {ScannerComponent} from './3-logged-in/1-scanner/scanner.component'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {QR_SCANNER_FACTORY} from './application/qr-scanner.provider'
import {QrFactory} from './application/qr-factory.class'
import {HeaderComponent} from './common/header/header.component'
import {WelcomeComponent} from './1-welcome/welcome.component'
import {FooterComponent} from './common/footer/footer.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {HelpComponent} from './2-help/help.component'
import {ScanResultDialogComponent} from './common/scan-result-dialog/scan-result-dialog.component'
import {ReactiveFormsModule} from '@angular/forms'
import {MatDialogModule} from '@angular/material/dialog'
import {ErrorDialogComponent} from './common/error-dialog/error-dialog.component'
import {CompanySignUpComponent} from './4-company-sign-up/company-sign-up.component'
import {RegisterDoneComponent} from './4-company-sign-up/register-done/register-done.component'

import {MatSnackBarModule} from '@angular/material/snack-bar'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'

@NgModule({
  declarations: [
    AppComponent,
    ScannerComponent,
    HeaderComponent,
    WelcomeComponent,
    FooterComponent,
    ErrorDialogComponent,
    CompanySignUpComponent,
    ErrorDialogComponent,
    HelpComponent,
    ScanResultDialogComponent,
    RegisterDoneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BankIdModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule
  ],

  providers: [
    {provide: QR_SCANNER_FACTORY, useClass: QrFactory},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    LOCAL_STORAGE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
