import { Component } from '@angular/core'
import {SCAN_ROUTE_PATH} from "../application/data-types"

@Component({
  selector: 'aku-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {

  protected readonly SCAN_ROUTE_PATH = SCAN_ROUTE_PATH
}
