export const ACCESS_TOKEN_NAME = 'id-token'

export interface BankIDVerificationItem {
  /**
   * Only used in frontend ui
   */
  title?: string
  /**
   * What is from bankId
   */
  verificationData?: BankIDVerificationData
  verificationError?: BankIDVerificationError
}

export interface BankIDVerificationData {
  /**
   * Only used in frontend ui
   */
  title?: string

  /**
   * Our data that we think is relevant
   */
  itemId: string
  timeStamp: number
  sub: string
  quota: number

  /**
   * What is from bankId
   */
  transactionType: string
  authentication: Authentication
  verification: Verification
  user: Client
}

export interface BankIDVerificationError {
  /**
   * Only used in frontend ui
   */
  title?: string
  /**
   * Our data that we think is relevant
   */
  itemId: string
  timeStamp: number
  sub: string
  /**
   * What is from bankId
   */
  errorCode: string
  details: string
}

export interface Authentication {
  identifiedAt: string
  orderRef: string
  signature: string
  ocspResponse: string
}

export interface Verification {
  verifiedAt: string
  signature: string
}

export interface Client {
  personalNumber: string
  name: string
  givenName: string
  surname: string
  age: number
  quota: number
}

export interface VerifyData {
  qrCode: string
}
