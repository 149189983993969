import {Component} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {environment} from '../../environments/environment'
import {Router} from '@angular/router'
import {verify} from '@akiru/personnummer'

export function validateVatNumber(control: FormControl): { [key: string]: any } | null {
  // Remove everything that is not a number
  const vatNumber: string = control.value.replace(/\D/g, '')

  /**
   * Return null if organisationsnummer is enskild firma
   */
  try {
    verify(vatNumber)
    return null
  } catch { /* empty */
  }

  // Check if the value is empty
  if (!vatNumber) {
    return {required: true}
  }

  // Check the length of the value
  if (vatNumber.length !== 10) {
    return {invalidLength: true}
  }

  // Check that the third digit is at least '2'
  if (vatNumber.charAt(2) < '2') {
    return {invalidThirdDigit: true}
  }

  // Check the control digit
  const controlDigit = Number(vatNumber.charAt(9))
  const sum = vatNumber.split('').slice(0, 9).reduce((acc, digit, index) => {
    const multiplier = index % 2 === 0 ? 2 : 1
    const product = Number(digit) * multiplier
    return acc + (product > 9 ? product - 9 : product)
  }, 0)
  const calculatedControlDigit = (10 - (sum % 10)) % 10

  if (controlDigit !== calculatedControlDigit) {
    return {invalidControlDigit: true}
  }

  // Return null if no validation rule has been violated
  return null
}


@Component({
  selector: 'aku-company-sign-up',
  templateUrl: './company-sign-up.component.html',
  styleUrls: ['./company-sign-up.component.scss']
})
export class CompanySignUpComponent {
  public bankIdUrl = environment.apiUrl

  constructor(private router: Router) {
  }

  public get vatNumber(): FormControl {
    return this.form.get('vatNumber') as FormControl
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    street: new FormControl<string>('', [Validators.required]),
    vatNumber: new FormControl<string>('', [Validators.required, validateVatNumber])
  })

  public afterSigned(event: any) {
    return this.router.navigate(['/registered', {eventData: JSON.stringify(event)}])
  }

  public sanitizeForm(): any {
    const vatNumberControl = this.form.get('vatNumber')
    const sanitizedVatNumber = vatNumberControl?.value.replace(/\D/g, '')
    vatNumberControl?.setValue(sanitizedVatNumber)
    return this.form.value
  }
}
